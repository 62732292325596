import throttleAsyncFunction    from '@/client/utilities/throttleAsyncFunction.js';
import getAsyncOps              from '@/client/extensions/composition/asyncOperations.js';
import {helpers}                from '@vuelidate/validators';

export default function (list) {
    let validator = (value) =>  {
        return list.includes(value);
    };

    return helpers.withParams(
        {type: 'inList'},
        validator
    );
};
